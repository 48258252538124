<template>
  <div id="qstu">
    <div class="right">
      <div class="title">
        <div class="left_title">
          <div class="img">
            <img src="" alt="" />
          </div>
          <b>问卷结果</b>
        </div>
        <div class="right_title">导出数据</div>
      </div>
      <div class="right_info">
        <div class="select">
          <el-select
            v-model="option"
            placeholder="请选择班级"
            style="margin-right: 0.2rem"
          >
            <el-option
              label="铁道供电A1901班"
              value="铁道供电A1901班"
            ></el-option>
          </el-select>
          <el-select v-model="timer" placeholder="请选择时间">
            <el-option label="2021" value="2020"></el-option>
          </el-select>
        </div>
        <table>
          <tr>
            <th>序号</th>
            <th>问题题干</th>
            <th colspan="2">选项A</th>
            <th colspan="2">选项B</th>
            <th colspan="2">选项C</th>
            <th colspan="2">选项D</th>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span
                style="
                  display: inline-block;
                  max-width: 3.67rem;
                  text-align: left;
                "
                >当铺设轨道的路基面低于自然地面，经填筑而形成的路基称之为（
                ）</span
              >
            </td>
            <td>已完全掌握所有知识点</td>
            <td>77%</td>
            <td>已掌握一半知识点</td>
            <td>10%</td>
            <td>已掌握部分知识点</td>
            <td>3%</td>
            <td>未掌握知识点</td>
            <td>10%</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import * as util from "@/unit/network";
export default {
  data() {
    return {
      option: "",
      timer: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
#qstu {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    // display: flex;
    width: 100%;
    .title {
      height: 72px;
      padding: 19px 34px 19px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdcdcd;
      .left_title {
        display: flex;
        align-items: center;
        color: #131313;
        font-weight: 500;
        .img {
          width: 26px;
          height: 27px;
          margin-right: 9px;
        }
      }
      .right_title {
        color: #fff;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #1c5eff;
        border-radius: 5px;
      }
    }
    .right_info {
      height: calc(100% - 72px);
      background: #fff;
      overflow: hidden;
      .select {
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 115px;
        /deep/.el-select {
          width: 176/96in;
        }
        /deep/.el-input__inner {
          height: 27/96in;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      table {
        width: 1360px;
        margin-top: 5px;
        margin-left: 65px;
        border-collapse: collapse;
        border: 1px solid #bebec0;
        font-size: 16px;
        th {
          font-weight: 400;
          background-color: #e9ebee;
          height: 52px;
        }
        td,
        th {
          border-collapse: collapse;
          border: 1px solid #bebec0;
        }
        td {
          height: 80px;
          text-align: center;
        }
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>